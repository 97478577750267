<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-06-26 09:59:37
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2024-03-06 14:24:32
 * @FilePath: \yda_web_manage\src\views\businessManage\staff\components\organization.vue
 * @Description: 
 * 
-->
<template>
  <div class="box">
    <div class="content-btn">
      <div class="search-view">
        <a-input
          class="form-input"
          allowClear
          v-model:value="inputVal"
          :maxlength="30"
          placeholder="请输入组织名称查询"
        >
          <template #prefix>
            <img src="@/assets/svg/search.svg" alt="" />
          </template>
        </a-input>
        <a-button type="primary" @click="handledSearchValue" class="form-btn">查询</a-button>
      </div>
      <div class="search-view">
        <!-- 添加组织 -->
        <!-- <a-button type="primary" class="add-btn form-btn" @click="openModal('add', 'treeAdd')" v-btn="'PC_DEPT_ADD'"
          ><PlusOutlined class="add-icon" />添加组织</a-button
        > -->
        <!-- 导入组织 -->
        <!-- <a-button class="form-btn" @click="openModal('export', 'treeExport')" v-btn="'PC_DEPT_UPDATE'"
          >导入组织</a-button
        > -->
      </div>
    </div>
    <a-spin :spinning="loading">
      <s-table
        :columns="columns"
        class="global-ant-table"
        @row-click="handleRowClick"
        @rowDragEnd="rowDragEnd"
        @expandedRowsChange="expandedRowsChange"
        :defaultExpandedRowKeys="[0]"
        :expandedRowKeys="expandedKeys"
        :auto-expand-parent="autoExpandParent"
        :rowKey="row => row.departmentId"
        childrenColumnName="childrenDepartmentList"
        :data-source="treeData"
        :pagination="false"
        :height="500"
        :checkStrictly="false"
      >
        <!-- <template #expandIcon="props">
          <span v-if="props.record.childrenDepartmentList.length > 0">
            <div
              v-if="props.expanded"
              style="display: inline-block; margin-right: 10px"
              @click="
                e => {
                  props.onExpand(props.record, e)
                }
              "
            >
              <CaretDownOutlined style="font-size:18px" />
            </div>
            <div
              v-else
              style="display: inline-block; margin-right: 10px"
              @click="
                e => {
                  props.onExpand(props.record, e)
                }
              "
            >
              <CaretRightOutlined style="font-size:18px" />
            </div>
          </span>
          <span v-else style="width:28px"></span>
        </template> -->
        <template #bodyCell="{  record, column }">
          <!-- <slot :text="text" :index="index" :record="record" :column="column" name="slot"></slot> -->
          <template v-if="column.key === 'departmentName'">
            <!-- <div class="tree-item"> -->
            <img
              v-if="record.type == 1"
              :src="require('@/assets/images/staff/department.png')"
              class="org-icon"
              alt=""
              srcset=""
            />
            <img v-else :src="require('@/assets/images/staff/company.png')" class="org-icon" alt="" srcset="" />
            <!-- <a-tooltip placement="topLeft" :title="record.departmentName"> -->
            <span class="tree-item-sty" v-html="handledHtml(record)" />
            <!-- </a-tooltip> -->
            <!-- </div> -->
          </template>
          <template v-if="column.key === 'type'">
            <span>{{ isGov && record.type == 3 ? '单位' : typeList[record.type] }}</span>
          </template>
          <template v-if="column.key === 'operation'">
            <a>Action</a>
          </template>
          <!-- 操作 -->
          <template v-if="column.dataIndex === 'action'">
            <div class="tab-btn" v-if="record.departmentId != 0 && !record.disabled">
              <!-- (record.departmentId != 0 && record.canCreateStaff) || (record.type == 2 && record.departmentId != 0) -->
              <a
                @click.stop="openModal('edit', 'treeEdit', record)"
                style="margin-right: 16px"
                v-btn="'PC_DEPT_UPDATE'"
                class="table-text-color"
                >修改</a
              >
              <a class="table-text-color" @click.stop="openModal('delete', 'delete', record)" v-btn="'PC_DEPT_DELETE'"
                >删除</a
              >
            </div>
          </template>
        </template>
        <!-- <template #tooltipTitle="{ value }">
          {{ value }}
        </template> -->
      </s-table>
    </a-spin>
    <modal
      :isAdd="isAdd"
      v-model:visible="visible"
      v-model:loading="loading"
      :type="actionType"
      :departmentList="treeData"
      @modalSubmit="modalSubmit"
      :current="current"
      :departmentId="departmentId"
    />

    <a-modal
      v-model:visible="modalShow"
      centered
      :width="502"
      :closable="false"
      class="delete-modal"
      @cancel="deleteModalCancel"
      @ok="deleteModalOk"
    >
      <div class="top-box">
        <ExclamationCircleFilled style="color: #FAAD14; font-size: 21px" />
        <div class="top-title">提示！</div>
      </div>
      <div class="center-box">
        <div class="headline-title">删除部门后，本部门关联的数据将不可见，请将数据归属转移后再进行删除</div>
        <div class="transmit-box">
          转交至：
          <div class="check-btn" @click="openModal('open', 'open')">
            <div class="check-box" v-if="checkDep.length">
              <div class="dep-title text-ellipsis">{{ checkDep[0].dataName }}</div>
              -
              <div class="company-name">
                <div class="company-name-box">
                  <div class="name">
                    {{ checkDep[0].ownershipName }}
                  </div>
                </div>
              </div>
            </div>
            <span v-else>请选择部门</span>
          </div>
          <DownOutlined class="icon-img" />
        </div>
      </div>
    </a-modal>

    <a-modal
      centered
      title="请选择部门"
      width="655px"
      class="global-range-modal"
      :visible="globalSelectorVisible"
      @cancel="globalSelectorCancel"
      @ok="globalSelectorOk"
    >
      <GlobalSelector
        :visible="globalSelectorVisible"
        :selectedList="checkDep"
        :selectorInfo="selectorInfo"
        :renderList="renderList"
        @getSearchInfo="getSearchInfo"
        @toNext="toNext"
        ref="globalSelector"
      >
      </GlobalSelector>
    </a-modal>
  </div>
</template>

<script>
import { watch, reactive, toRefs, computed, createVNode, onMounted, ref } from 'vue'
import STable from '@surely-vue/table'
import '@surely-vue/table/dist/index.css'
import cloneDeep from 'lodash/cloneDeep'
import { Modal } from 'ant-design-vue'
import GlobalSelector from '@/components/GlobalSelector'
import modal from '@/views/businessManage/department/components/modal'
import { notification } from 'ant-design-vue'
import {
  addDepartment,
  deleteDepartment,
  editDepartment,
  getDepartmentTab,
  importDepartment,
  sortDepartmentTab
} from '@/apis/businessManage'
import { cmsNotice } from '@/utils/utils'
import { formateListData } from '@/utils/selectorListFormate.js'
import { getDepartmentOrStaff } from '@/apis/businessManage'
import { ExclamationOutlined, PlusOutlined, ExclamationCircleFilled, DownOutlined } from '@ant-design/icons-vue'
export default {
  name: 'Tissue',
  components: {
    modal,
    STable,
    PlusOutlined,
    ExclamationCircleFilled,
    DownOutlined,
    GlobalSelector
  },
  setup(props, context) {
    const state = reactive({
      enterpriseId: JSON.parse(localStorage.getItem('yda-admin-userInfo')).result.enterpriseId,
      autoExpandParent: true,
      expandedKeys: [0],
      departmentId: 0,
      searchValue: '',
      visible: false, //树结构显示框
      isAdd: false, //添加部门
      // 请求 loading
      loading: false,
      actionType: 'add',
      select: '',
      inputVal: '',
      current: null,
      // 表格数据
      treeData: [],
      typeList: ['集团', '部门', '', '公司'],
      // 表格数据 (cloneDeep)
      deepCloneTreeData: [],
      // 二次确认弹窗
      showConfirm: false,
      confirmContent: undefined,
      confirmParams: {},
      confirmIndex: 1,
      confirmSuccess: '',
      // 隐藏缺省页，true-隐藏，false-展示
      hideNoData: true,
      // url传参
      query: props.query,
      modalShow: false, //删除弹窗
      globalSelectorVisible: false, // 选择部门的弹窗
      checkDep: [], // 选择的部门
      deleteLoading: false, // 删除loading
      checkDepId: ''
    })

    const selectorInfo = reactive({
      placeholder: '请输入部门名称查询111',
      limitCount: 1,
      ableSelectDep: true,
      limitWarningInfo: '当前已添加至部门上限请删除已选部门后重试'
    })

    const columns = [
      {
        title: '组织名称',
        dataIndex: 'departmentName',
        key: 'departmentName',
        rowDrag: true,
        align: 'left',
        ellipsis: true
        // tooltip: true,
        // ellipsis: { showTitle: false }
      },
      {
        title: '组织类型',
        dataIndex: 'type',
        key: 'type',
        align: 'left',
        width: '20%'
      },
      {
        title: '成员数',
        dataIndex: 'members',
        key: 'members',
        align: 'left',
        width: '20%'
      },
      {
        title: '主管',
        dataIndex: 'leaderName',
        key: 'leaderName',
        align: 'left',
        width: '20%',
        ellipsis: true
      },
      // {
        // title: '操作',
        // dataIndex: 'action',
        // key: 'action',
        // align: 'left',
        // width: 140
      // }
    ]

    const isGov = computed(() => {
      // console.log('这里的数据啊', i, JSON.parse(localStorage.getItem('yda-admin-userInfo')).result.selfType);
      let flag = JSON.parse(localStorage.getItem('yda-admin-userInfo')).result.selfType == 2
      return flag
    })

    const dataList = computed(() => {
      const generate = tempArr => {
        return tempArr.reduce((result, item) => {
          const chilren = generate(item.childrenDepartmentList || [])
          return [...result, ...chilren, item]
        }, [])
      }
      return generate(state.treeData)
    })
    const expandedRowsChange = keys => {
      console.log(keys)
      state.expandedKeys = keys
      state.autoExpandParent = false
    }
    const getIds = id => {
      let ids = []
      const fn = arr => {
        arr.some(item => {
          if (item.departmentId == id) {
            ids.push(id)
            // 编辑部门时，禁止选择当前修改的部门
            return true
          }
          if (item.childrenDepartmentIdSet?.includes(+id)) {
            ids.push(item.departmentId)
            return fn(item.childrenDepartmentList)
            // return true
          }

          return false
        })
      }
      fn(state.treeData)
      return ids
    }
    const handledSearchValue = () => {
      state.searchValue = state.inputVal
      if (!state.searchValue) {
        state.expandedKeys = []
        state.autoExpandParent = true
        return
      }
      const searchIds = dataList.value
        .filter(item => {
          return item.departmentName.includes(state.searchValue)
        })
        .map(item => item.parentId)
      if (!searchIds.length) {
        state.expandedKeys = []
        return
      }
      console.log(searchIds, dataList)
      const resultList = []
      searchIds.map(item => {
        resultList.push(...getIds(item))
      })
      state.autoExpandParent = true
      console.log(resultList)
      state.expandedKeys = Array.from(new Set([...state.expandedKeys, ...resultList]))
    }
    const modalSubmit = async value => {
      let isSuccess = false
      if (state.actionType === 'export') {
        const res = await importDepartment({ file: value })
        if (res.success) {
          isSuccess = true
          cmsNotice('success', '上传成功')
        }
      } else if (state.actionType === 'add' || state.actionType === 'addChild') {
        const params = {
          departmentName: value.department,
          parentId: value.nextDepart || 0,
          type: value.type,
          leaderId: value.leaderId
        }
        const res = await addDepartment(params)
        if (res.success) {
          cmsNotice('success', '添加成功')
          isSuccess = true
          state.loading = false
          if (isSuccess) {
            await getDepartment()
            state.visible = false
            state.staffVisible = false
          }
          return
        }
      } else if (state.actionType === 'edit') {
        const params = {
          departmentName: value.department,
          parentId: value.nextDepart || 0,
          type: value.type,
          leaderId: value.leaderId,
          id: state.current.departmentId
        }
        console.log(value, params)
        const res = await editDepartment(params)
        if (res.success) {
          isSuccess = true
          cmsNotice('success', '修改成功')
        }
      }
      state.loading = false
      state.departmentId = 0
      if (isSuccess) {
        await getDepartment()
        state.visible = false
        state.staffVisible = false
      }
    }
    //树菜单的添加部门和批量导入
    const openModal = (type, sel, e) => {
      console.log('e', e, sel)
      //左侧树结构的底部添加部门按钮
      if (sel === 'treeAdd') {
        state.actionType = type
        state.visible = true
        state.isAdd = true
      }
      if (sel === 'treeEdit') {
        state.actionType = type
        state.visible = true
        state.isAdd = true
        state.current = e
        state.actionType = type
      }
      if (sel === 'delete') {
        // Modal.confirm({
        //   title: '是否删除！',
        //   width: '25%',
        //   content: '删除后无法恢复，是否删除？',
        //   centered: true,
        //   confirmLoading: state.loading,
        //   icon: createVNode(ExclamationOutlined),
        //   onOk: async () => {
        //     const res = await deleteDepartment({ id: e.departmentId })
        //     if (res.success) {
        //       cmsNotice('success', '删除成功')
        //       await getDepartment()
        //     }
        //   }
        // })
        state.checkDep = []
        state.modalShow = true
        state.checkDepId = e.departmentId
      }
      if (sel === 'treeExport') {
        state.actionType = type
        state.visible = true
        state.isAdd = false
      }
      if (sel === 'open') {
        state.globalSelectorVisible = true
        getScopeList()
      }
    }

    // 部门弹窗实例
    const globalSelector = ref(null)
    //弹窗确实事件
    const globalSelectorOk = () => {
      let arr = globalSelector.value.okEvent()
      console.log('arr', arr)
      state.checkDep = [...arr]
      // state.selectedList = [...arr]
      globalSelectorCancel()
    }
    // 弹窗取消事件
    const globalSelectorCancel = () => {
      state.globalSelectorVisible = false
      globalSelector.value.cancelEvent()
    }
    // 删除弹窗取消
    const deleteModalCancel = () => {
      state.modalShow = false
    }
    // 删除弹窗确认
    const deleteModalOk = async () => {
      if (state.deleteLoading) return
      state.deleteLoading = true
      if (!state.checkDep.length) {
        notification['warning']({
          message: '提示',
          description: '请选择转交部门'
        })
        state.deleteLoading = false
        return
      }
      console.log('当前权限', state.checkDep[0])
      const res = await deleteDepartment({ id: state.checkDepId, toDeptId: state.checkDep[0].dataId })
      if (res.success) {
        cmsNotice('success', '删除成功')
        state.deleteLoading = false
        await getDepartment()
      }
      state.deleteLoading = false
      deleteModalCancel()
    }

    const renderList = ref()
    //去下级
    const toNext = id => {
      let data = {
        departmentId: id
      }
      getScopeList(data)
    }
    //搜索
    const getSearchInfo = async e => {
      let data = {
        searchName: e
      }
      getScopeList(data)
    }
    // 处理左侧数据
    const handelData = res => {
      renderList.value = formateListData(res)
      console.log('处理后的renderList的值', renderList.value)
    }
    //弹窗数据
    const getScopeList = async data => {
      let params = {
        selectorType: 2 //1部门人员2部门3员工
      }
      const res = await getDepartmentOrStaff({ ...params, ...data })
      if (res.success) {
        // console.log('全部数据', res)
        handelData(res)
      }
    }

    // 获取数据列表
    const getDepartment = async () => {
      const res = await getDepartmentTab()
      if (res.success) {
        state.treeData = res.data
        state.copyDepartmentList = res.data
        if (state.departmentId == 0) {
          state.departmentTitle = res.data[0].departmentName
        }
      }
    }
    const handledHtml = item => {
      return item.departmentName.replace(
        state.searchValue,
        `<div style='color: #b32d27;display: inline'>${state.searchValue}</div>`
      )
    }
    // 二次确认类操作
    const handleConfirm = () => {}

    // 表格排序回调
    const onChangeSorter = (pagination, filters, sorter) => {
      if (sorter.order) {
        state.search.sortField = sorter.field
        state.search.ascOrDesc = sorter.order === 'ascend'
        if (sorter.column.asc) {
          // 兼容后端 表格不同字段排序处理
          state.search[sorter.column.asc] = sorter.order === 'ascend'
        }
      } else {
        state.search.sortField = undefined
        state.search.ascOrDesc = undefined
      }
      getDepartment()
    }

    // 拖拽判断
    const rowDragEnd = row => {
      return new Promise((resolve, reject) => {
        console.log('promise', row)
        let currentParentId = row.record.parentId
        let nextParentId = row?.nextTargetInfo?.record?.parentId
        let preParentId = row?.preTargetInfo?.record?.parentId
        console.log(currentParentId, nextParentId, preParentId)
        // 集团和非同层级 禁止移动
        if ((row?.insertToRowKey !== null && currentParentId !== row?.insertToRowKey) || row.record.parentId == null) {
          console.log('非同级组织')
          reject()
          return
        }
        state.deepCloneTreeData = cloneDeep(state.treeData)
        if (row?.insertToRowKey !== null) {
          if (row.record.type === 2) {
            console.log('子公司只能在最外层')
            reject()
            return
          }
          const findContainingObject = (array, id) => {
            // let result = []
            for (const item of array) {
              if (item.departmentId === id) {
                return item.childrenDepartmentList
              }
              if (item.childrenDepartmentList) {
                const result = findContainingObject(item.childrenDepartmentList, id)
                if (result) return result
              }
            }
          }
          const list = findContainingObject(state.deepCloneTreeData, row.record.parentId)
          const flag = verifySlot(row, list)
          flag ? resolve(true) : reject()
        } else {
          // 最外层子公司在排序
          //禁止移动到集团上面 只有子公司类型 才可以
          if ((row.nextTargetInfo && row?.nextTargetInfo?.record?.departmentId === '0') || row.record.type !== 2) {
            console.log('最外层只有子公司可以挪动，并且不能放集团上面')
            reject()
            return
          }
          const list = state.treeData.filter(item => {
            return item.type === 2
          })
          const flag = verifySlot(row, list)
          flag ? resolve(true) : reject()
          // resolve(true)
        }
      })
    }
    // 排序请求数据处理
    const verifySlot = (row, list) => {
      const oldIndex = list.findIndex(item => item.departmentId == row.record.departmentId)
      const removeItemList = list.splice(oldIndex, 1)
      if (row.nextTargetInfo && row.record.parentId == row.nextTargetInfo.record.parentId) {
        const newIndex = list.findIndex(item => item.departmentId == row.nextTargetInfo.record.departmentId)
        if (newIndex == '-1' || oldIndex == newIndex) {
          return false
        }
        list.splice(newIndex, 0, removeItemList[0])
      } else {
        list.push(removeItemList[0])
      }
      const soltList = list.map((item, index) => {
        return { departmentId: item.departmentId, sort: index + 1 }
      })
      sortDepartmentTab(row.record.enterpriseId, soltList).then(res => {
        console.log('res', res)
        if (res.success) {
          getDepartment()
          return true
        } else {
          return false
        }
      })
    }
    const expand = (e, item) => {
      console.log('e-item', e, item)
    }
    const handleRowClick = (event, row, column) => {
      console.log('触发了点击事件')
      this.$refs.table.clearSelection()
      // event.stopPropagation()
    }

    // 初次渲染
    onMounted(() => {
      // 去除水印
      let domArr = document.querySelectorAll('.surely-table div')
      for (let i = 0; i < domArr.length; i++) {
        if (['Unlicensed Product'].includes(domArr[i].innerText)) {
          domArr[i].remove()
        }
        if (['Powered by Surely Vue'].includes(domArr[i].innerText)) {
          domArr[i].innerText = ''
        }
      }
      getDepartment()
    })
    return {
      ...toRefs(state),
      onChangeSorter,
      rowDragEnd,
      expand,
      columns,
      handledHtml,
      openModal,
      modalSubmit,
      handledSearchValue,
      expandedRowsChange,
      getDepartment,
      handleRowClick,
      globalSelectorCancel,
      globalSelector,
      globalSelectorOk,
      renderList,
      getSearchInfo,
      selectorInfo,
      toNext,
      deleteModalCancel,
      deleteModalOk,
      isGov
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  background-color: #ffffff;
  color: black;
  padding: 24px;
  .content-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
    .search-view {
      display: flex;
      .form-input {
        width: 272px;
      }
      .form-btn {
        margin-left: 16px;
      }
    }
  }

  .tab-btn {
    display: flex;
    .table-text-color {
      color: #1890ff;
    }
  }
  .name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .span-container {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100px;
      width: auto;
    }
    img {
      width: 52px;
      height: 28px;
      margin-left: 4px;
    }
  }
  .span-department {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 120px;
  }
}
.tab-btn {
  display: flex;
  justify-content: flex-start;
  .table-text-color {
    color: #1890ff;
  }
}
.tree-item {
  display: flex;
  align-items: center;
  padding: 0 25px 0 5px;
  // width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .tree-btn {
    position: absolute;
    right: 0;
    background: #ffffff;
  }

  .select {
    color: #0A7BFF;
  }
}
.org-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.tree-item-style {
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  .tree-item-sty {
    display: inline;
  }
}

.department-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
:deep(.surely-table-body .surely-table-body-cell-range-selected.surely-table-body-cell-range-single-cell) {
  border: 0;
  background-color: #fff;
}
:deep(.surely-table-body-cell) {
  border: 0;
}
.top-box {
  display: flex;

  .top-title {
    margin-left: 17px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }
}
.center-box {
  margin: 12px 0 0 40px;

  .headline-title {
    font-size: 14px;
    font-weight: 400;
    color: #595959;
    line-height: 22px;
  }

  .transmit-box {
    margin-top: 24px;
    display: flex;
    align-items: center;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    font-size: 14px;
    position: relative;

    .check-btn {
      width: 334px;
      height: 32px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #d9d9d9;
      padding: 5px 12px;
      cursor: pointer;

      .check-box {
        display: flex;
        // align-items: center;
        width: 289px;
      }
      .dep-title {
        max-width: 168px;
      }

      .company-name {
        flex: 1;
        overflow: hidden;
      }
      .company-name-box {
        display: flex;
      }

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .comp-title {
        // max-width: 112px;
      }
    }
    .icon-img {
      font-size: 12px;
      position: absolute;
      right: 20px;
      color: rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }
  }
}
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
